.fade_logo{
    position: absolute;
    width: 450px;
    top: 30%;
    left: 50%;
    transform: translate(-49%, 2%);
}

.theme-color-primary{
    color:#555658
}

.theme-color-blue{
    color: #1891FC;
}

.theme-color-darkblue{
    color: #032d60;
}

.discuss-selector span{
    font-size: 11px;
}
.question-check-holder label span svg {
    width: 14px;
    height: 14px;
}
.question-check-holder label span {
    padding-block: 3px;
}
.radio-button-holder label span {
    font-size: 11px;
}
.radio-button-holder label span span svg {
    width: 14px;
    height: 14px;
}